/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Crypto Currency metadata
 * @export
 * @interface CryptoCurrencyMetadata
 */
export interface CryptoCurrencyMetadata {
  /**
   *
   * @type {string}
   * @memberof CryptoCurrencyMetadata
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CryptoCurrencyMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CryptoCurrencyMetadata
   */
  networkName: string;
  /**
   *
   * @type {string}
   * @memberof CryptoCurrencyMetadata
   */
  iconUrl: string;
}

/**
 * Check if a given object implements the CryptoCurrencyMetadata interface.
 */
export function instanceOfCryptoCurrencyMetadata(value: object): value is CryptoCurrencyMetadata {
  if (!('code' in value) || value['code'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('networkName' in value) || value['networkName'] === undefined) return false;
  if (!('iconUrl' in value) || value['iconUrl'] === undefined) return false;
  return true;
}

export function CryptoCurrencyMetadataFromJSON(json: any): CryptoCurrencyMetadata {
  return CryptoCurrencyMetadataFromJSONTyped(json, false);
}

export function CryptoCurrencyMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoCurrencyMetadata {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
    name: json['name'],
    networkName: json['network_name'],
    iconUrl: json['icon_url'],
  };
}

export function CryptoCurrencyMetadataToJSON(json: any): CryptoCurrencyMetadata {
  return CryptoCurrencyMetadataToJSONTyped(json, false);
}

export function CryptoCurrencyMetadataToJSONTyped(
  value?: CryptoCurrencyMetadata | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    code: value['code'],
    name: value['name'],
    network_name: value['networkName'],
    icon_url: value['iconUrl'],
  };
}
