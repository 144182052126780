/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import {
  CryptoMoneyFromJSON,
  CryptoMoneyFromJSONTyped,
  CryptoMoneyToJSON,
  CryptoMoneyToJSONTyped,
} from './CryptoMoney';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';
import type { CryptoTransferStatus } from './CryptoTransferStatus';
import {
  CryptoTransferStatusFromJSON,
  CryptoTransferStatusFromJSONTyped,
  CryptoTransferStatusToJSON,
  CryptoTransferStatusToJSONTyped,
} from './CryptoTransferStatus';

/**
 *
 * @export
 * @interface CryptoTransfer
 */
export interface CryptoTransfer {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof CryptoTransfer
   */
  blockchainTransactionHash?: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof CryptoTransfer
   */
  destination: CryptoWalletAddressWithTag;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof CryptoTransfer
   */
  id: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof CryptoTransfer
   */
  receivedCrypto?: CryptoMoney;
  /**
   *
   * @type {CryptoTransferStatus}
   * @memberof CryptoTransfer
   */
  status: CryptoTransferStatus;
  /**
   *
   * @type {boolean}
   * @memberof CryptoTransfer
   */
  expired?: boolean;
}

/**
 * Check if a given object implements the CryptoTransfer interface.
 */
export function instanceOfCryptoTransfer(value: object): value is CryptoTransfer {
  if (!('destination' in value) || value['destination'] === undefined) return false;
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function CryptoTransferFromJSON(json: any): CryptoTransfer {
  return CryptoTransferFromJSONTyped(json, false);
}

export function CryptoTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransfer {
  if (json == null) {
    return json;
  }
  return {
    blockchainTransactionHash:
      json['blockchain_transaction_hash'] == null ? undefined : json['blockchain_transaction_hash'],
    destination: CryptoWalletAddressWithTagFromJSON(json['destination']),
    id: json['id'],
    receivedCrypto: json['received_crypto'] == null ? undefined : CryptoMoneyFromJSON(json['received_crypto']),
    status: CryptoTransferStatusFromJSON(json['status']),
    expired: json['expired'] == null ? undefined : json['expired'],
  };
}

export function CryptoTransferToJSON(json: any): CryptoTransfer {
  return CryptoTransferToJSONTyped(json, false);
}

export function CryptoTransferToJSONTyped(value?: CryptoTransfer | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    blockchain_transaction_hash: value['blockchainTransactionHash'],
    destination: CryptoWalletAddressWithTagToJSON(value['destination']),
    id: value['id'],
    received_crypto: CryptoMoneyToJSON(value['receivedCrypto']),
    status: CryptoTransferStatusToJSON(value['status']),
    expired: value['expired'],
  };
}
