import { VueQueryPlugin } from '@tanstack/vue-query';
import { createApp } from 'vue';
import './style.css';
import { createSimplexUI, sThemeLight } from '@simplex/simplex-ui';
import { translationConfig } from '@simplex/ui';
import type { Api } from './app/api/api';
import { clientKey } from './app/api/use-client';
import { createAppRouter, routerKey } from './app/navigation/router';
import { routes } from './app/navigation/routes';
import en from './app/translations/locales/en';
import App from './App.vue';
import {
  Configuration,
  CryptoExecutionsApi,
  CryptoTransfersApi,
  OtpApi,
  PaymentIntentsApi,
  PayoutsApi,
} from './client';

const app = createApp(App);
app.use(translationConfig({ en }));
app.use(VueQueryPlugin);

const appRouter = createAppRouter(routes);
app.use(appRouter.router);
app.use(createSimplexUI({ theme: sThemeLight }));
app.provide<Api>(clientKey, {
  paymentIntents: new PaymentIntentsApi(
    new Configuration({
      basePath: '',
    }),
  ),
  payouts: new PayoutsApi(
    new Configuration({
      basePath: '',
    }),
  ),
  cryptoTransfers: new CryptoTransfersApi(
    new Configuration({
      basePath: '',
    }),
  ),
  cryptoExecutions: new CryptoExecutionsApi(
    new Configuration({
      basePath: '',
    }),
  ),
  otp: new OtpApi(
    new Configuration({
      basePath: '',
    }),
  ),
});

app.provide(routerKey, appRouter);

app.mount('#app');
